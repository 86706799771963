import React, { useCallback, useMemo } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldText, FieldTextArea, FieldUpload, Space, Alert, FieldHidden, useMountEffect } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';
import RequestsApi from '../RequestsApi';
import { FieldAddress } from '@dex/bubl-dash';
import { FieldRadio } from '@dex/bubl-dash';
import { Seperator } from '@dex/bubl-dash';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import { Col } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { MetaTable } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';

const RequestsCreateFormValidate: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, handleReset, merchant, mode } = props;

    useMountEffect(() => {

        if (mode === "edit") return;

        form.handleChange({ reset: true, silent: true, name: "amendMerchantDetails", value: "no" });
        form.handleChange({ reset: true, silent: true, name: "amend", value: data.merchant });
        form.handleChange({ reset: true, silent: true, name: "merchantId", value: data.merchant.id });
        form.handleChange({ reset: true, silent: true, name: "productId", value: data.merchant.productId });

    })

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            {merchant.existing == true &&
                <>
                    <Alert
                        type={"warning"}
                        description={"Merchant has pending request. Are you sure you want to add another?"}
                    />
                    <Space />
                </>
            }

            {mode !== "edit" &&
                <FieldHidden
                    form={form}
                    name='incomingType'
                    defaultValue={"manual"}
                />
            }

            <FieldRelationship
                form={form}
                label={"Merchant MID's"}
                name='merchantId'
                required={true}
                endpoint={'Merchants'}
                defaultValue={form.values.merchantId}
                disabled
                hidden
            />

            {mode !== "edit" &&
                <>

                    <Seperator
                        heading={"Merchant Details"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <MetaTable
                                data={data.merchant}
                                columnSize={{xs: 24, sm: 12}}
                                columnA={[
                                    {
                                        label: "Phone Number",
                                        key: "phone",
                                    },
                                    {
                                        label: "Email Address",
                                        key: "email",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Address",
                                        key: "address",
                                        format: "address"
                                    },
                                ]}
                            />
                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={"Amend Merchant Details"}
                                name='amendMerchantDetails'
                                required={false}
                                defaultValue={form.defaults.amendMerchantDetails}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                    </Row>

                    {form.values.amendMerchantDetails === "yes" &&
                    <>
                            <Line />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"Email Address"}
                                        name='amend.email'
                                        required={false}
                                        defaultValue={form.defaults.amend?.email}
                                        type={"email"}
                                        disabled={form.values.amendMerchantDetails !== "yes"}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldText
                                        form={form}
                                        label={"Phone Number"}
                                        name='amend.phone'
                                        required={false}
                                        defaultValue={form.defaults.amend?.phone || "+60"}
                                        disabled={form.values.amendMerchantDetails !== "yes"}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldAddress
                                        form={form}
                                        gutter={8}
                                        label={"Shipping Address"}
                                        name='amend.address'
                                        required={false}
                                        defaultValue={form.defaults.amend?.address}
                                        allowedCountries={["Malaysia"]}
                                        disabled={form.values.amendMerchantDetails !== "yes"}
                                    />

                                </Col>

                            </Row>



                        </>
                    }
                </>
            }

            {mode !== "edit" &&
                <>

                    <Seperator
                        heading={"Product Details"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <FieldRelationship
                        key={form.defaults.productId}
                        form={form}
                        name='productId'
                        defaultValue={form.defaults.productId}
                        endpoint='Products'
                        label='Product'
                        preload
                        disabled={mode == "edit"}
                    />

                </>
            }

            <FieldTextArea
                form={form}
                name='remarks'
                defaultValue={form.defaults.remarks}
                label='Remarks'
            />

            <FieldUpload
                form={form}
                folder={'uploads'}
                label={"Attachment"}
                name={`attachment`}
                required={false}
                defaultValue={form.defaults.attachment}
                limit={1}
            />

            <Space />

            <ButtonRow>
                <Button
                    label={"Submit Merchant Request"}
                    loading={process.loading}
                    confirm={merchant.existing == true ? {
                        title: `Merchant has pending request.`,
                        message: "Are your sure you want to perform this action?",
                    } : null}
                    onClick={form.handleSubmit.bind(null, handleSubmit)}
                />

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />
            </ButtonRow>

        </Form>

    );
}

export default RequestsCreateFormValidate;
