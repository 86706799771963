import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import RequestsApi from '../RequestsApi';
import RequestsCreateFormCheck from './RequestsCreateFormCheck';
import RequestsCreateFormValidate from './RequestsCreateFormValidate';

const RequestsCreateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const merchant = RequestsApi.create({}, "check");

    const [step, setStep] = useState("check");

    const handleReset = useCallback(() => {

        form.reset();
        process.reset();
        merchant.reset();

        setStep("check");

    }, []);

    const handleCheck = useCallback((values) => {

        merchant.run({
            data: values,
            onComplete: (response) => {

                form.handleChange({ name: "mid", value: response?.mid, reset: true })
                form.handleChange({ name: "productId", value: response?.merchant?.productId, reset: true })

            }
        })

    }, []);

    useEffect(() => {

        let step = "check";

        if (merchant.data) step = "validate";
        // if (process.data) step = "review";

        setStep(step);

    }, [merchant.loading, process.loading])

    return useMemo(() => (

        <Panel
            heading={"Request Details"}
        >

            {step === "check" &&
                <RequestsCreateFormCheck
                    form={form}
                    process={merchant}
                    handleSubmit={handleCheck}
                />
            }

            {step === "validate" && merchant.data &&
                <RequestsCreateFormValidate
                    data={merchant.data}
                    form={form}
                    merchant={merchant.data}
                    process={process}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />
            }

        </Panel>

    ), [form.hash, step, merchant.loading, process.loading]);

}

export default RequestsCreateForm;
