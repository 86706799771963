import React, { useMemo, } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldParseFile, InputTableCell, InputTableRow } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { loop, slug } from '@dex/bubl-helpers';
import { DataTable } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { Space } from '@dex/bubl-dash';
import { FieldNumber } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';

const RequestsImportCheckForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, columns, handleReset } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const parseData = async (data, file, buffer) => {

        const rows: any = [];

        loop(data, (row, index) => {

            rows.push({
                mid: row['mid'],
                numberOfRolls: row['number-of-rolls'],
                companyName: row['company-name'],
                tradingName: row['trading-name'],
                remarks: row['remarks'],
                email: row['email-address'],
                phone: row['phone-number'],
                line1: row['shipping-address-line-1'],
                line2: row['shipping-address-line-2'],
                city: row['city'],
                state: row['state'],
                country: row['country'],
                zipcode: row['zip-code'],
            });

        });

        form.reset({ requests: rows });
        form.setKey();

    };

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldParseFile
                        label={"Import File"}
                        required={true}
                        accept={".xlsx,.xls,.csv"}
                        onFileLoaded={parseData}
                        optsCSV={{
                            header: true,
                            dynamicTyping: false,
                            skipEmptyLines: true,
                            transformHeader: (text, index) => slug(text),
                        }}
                        optsXLS={{
                            toCSV: true,
                            singleSheet: true,
                        }}
                    />

                </Col>

            </Row>

            {form.values.requests && form.values.requests.length > 0 &&

                <div style={{ overflow: "auto" }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                label={"Vendor"}
                                name='vendorId'
                                required={true}
                                endpoint={'Vendors'}
                                preload={true}
                                defaultValue={form.defaults.vendorId}
                            />

                        </Col>

                    </Row>

                    <Space />

                    <DataTable
                        data={null}
                        columns={columns}
                        className={"input-table"}
                        style={{ overflowY: "scroll" }}
                    >

                        {form.values.requests.map((request, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].mid`}
                                        defaultValue={request.mid}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldNumber
                                        form={form}
                                        name={`requests[${index}].numberOfRolls`}
                                        defaultValue={request.numberOfRolls}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].companyName`}
                                        defaultValue={request.companyName}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].tradingName`}
                                        defaultValue={request.tradingName}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].remarks`}
                                        defaultValue={request.remarks}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].email`}
                                        defaultValue={request.email}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].phone`}
                                        defaultValue={request.phone}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].line1`}
                                        defaultValue={request.line1}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].line2`}
                                        defaultValue={request.line2}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].zipcode`}
                                        defaultValue={request.zipcode}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].city`}
                                        defaultValue={request.city}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].state`}
                                        defaultValue={request.state}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`requests[${index}].country`}
                                        defaultValue={request.country}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        ))}

                        <Space height={300} />

                    </DataTable>

                </div>

            }

            <Line />

            {form.values.requests && form.values.requests.length > 0 && (

                <ButtonRow>

                    <Button
                        label={"Check Requests"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={handleReset}
                    />

                </ButtonRow>

            )}

        </Form>


    ), [form.hash, form.changed, process.loading]);

}

export default RequestsImportCheckForm;
