import { baseApi, options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Requests';

const RequestsApi: any = baseApi(endpoint);

RequestsApi.getPaper = (options: options = {}): response => {

    return apiPost({
        url: endpoint + "/getPaper",
        ...options
    });

}

RequestsApi.import = (options?: options): response => {

    return RequestsApi.create(options, "import");

}

export default RequestsApi;