import React, { useMemo, useCallback } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import RequestsApi from '../../RequestsApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { validateDatePassed } from '../../../../functions/sharedValidation';

const RequestShippingForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({
        shippingStatus: data.shippingStatus,
        shippedOn: data.shippedOn,
        trackingNo: data.trackingNo,
        shippingProviderId: data.shippingProviderId
    });

    const process = RequestsApi.update(data.id, 'shipping', {
        onComplete: (data) => {

            //refresh view

            //temporary solution
            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    console.log(data, form.defaults);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date);

    }, []);

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldRadio
                        form={form}
                        label={"Shipping Status"}
                        name='shippingStatus'
                        required={true}
                        defaultValue={form.defaults.shippingStatus}
                        options={[
                            { value: "pending", label: "Pending" },
                            { value: "packed", label: "Packed" },
                            { value: "outbound", label: "Outbound" },
                            { value: "shipped", label: "Shipped" },
                        ]}
                    />

                </Col>

                {(form.values.shippingStatus === "outbound" || form.values.shippingStatus === "shipped") &&
                    <>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRelationship
                                form={form}
                                label={"Shipping Provider"}
                                name='shippingProviderId'
                                required={true}
                                endpoint={'ShippingProviders'}
                                preload={true}
                                defaultValue={form.defaults.shippingProviderId}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"Tracking No."}
                                name='trackingNo'
                                required={true}
                                defaultValue={form.defaults.trackingNo}
                            />

                        </Col>

                        {form.values.shippingStatus === "shipped" &&
                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldDate
                                    form={form}
                                    label={"Shipped On"}
                                    name='shippedOn'
                                    required={true}
                                    defaultValue={form.defaults.shippedOn}
                                    disabledDate={isDateDisabled}
                                />

                            </Col>
                        }

                    </>
                }



            </Row>

            <Line />

            <ButtonRow>
                <Button
                    label={"Submit"}
                    loading={process.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>

    ), [data, form, process]);

}

export default RequestShippingForm;
